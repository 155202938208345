/* Cookie popup*/
.termsfeed-com---nb-simple, .termsfeed-com---pc-overlay, .termsfeed-com---pc-overlay, .termsfeed-com---palette-dark {

    /* popup colors */

    --cookie-popup-bg: var(--white, #fff);
    --cookie-text-color: var(--black, #224A36FF);
    --cookie-text-hover-color: var(--gold, #D6A739);
    --font-size: 20px;
    --cookie-popup-border-color: var(--amazon-green, #346C50);
    --cooki-preferences-head-bg: var(--off-white, #E1E5E0);
    --cooki-preferences-footer-bg: var(--off-white, #E1E5E0);
    --link-color: var(--gold, #D6A739);

    --nav-link-color-hover:var(--amazon-green, #346C50);
    --nav-link-activ-bg: var(--amazon-green, #346C50);

    --button-color: var(--black, #224A36FF);
    --button-color-hover: var(--white, #fff);
    --button-bg: var(--gold, #D6A739);
    --button-bg-hover: var(--amazon-green, #346C50);
    --button-padding: 10px 15px;
    --button-font-size: ac(20px, 16px);
    --button-font-weighht: 700;
    --button-radius: 8px;
    --button-min-height: ac(48px, 38px);

}
.termsfeed-com---nb .cc-nb-main-container{
    padding: ac(66px, 33px) !important;
}
.termsfeed-com---palette-dark.termsfeed-com---nb {
    background-color: var(--cookie-popup-bg) !important;
    color: var(--cookie-text-color) !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

}

.termsfeed-com---reset {
    font-family: var(--font-main) !important;

    * {
        font-family: var(--font-main) !important;
    }
}

.termsfeed-com---palette-dark .cc-nb-title, .termsfeed-com---palette-dark .cc-nb-text {
    color: inherit !important;

}

.termsfeed-com---palette-dark .cc-nb-okagree, .termsfeed-com---palette-dark .cc-nb-reject, .termsfeed-com---palette-dark .cc-cp-foot-save, .termsfeed-com---palette-dark .cc-nb-changep {
    background: var(--button-bg) !important;
    border: 0;
    box-shadow: 0 2px 10px 0 rgba(235, 100, 37, 0);
    transition: color 0.3s, background 0.3s;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: var(--button-padding);
    font-weight: 600;
    cursor: pointer;
    border-radius: var(--button-radius);
    align-self: center;
    font: var(--button-font-size) var(--font-main)!important;
    min-height: var(--button-min-height);
    font-weight: var(--button-font-weighht)!important;
    color: var(--button-color) !important;
    &:hover {
        background: var(--button-bg-hover) !important;
        color: var(--button-color-hover) !important;
    }

    display: inline-flex!important;
    justify-content: space-between;
    gap: 8px;
    text-align: left;
    &::after{
        content: '\e900';
        font-family: 'icomoon' !important;
        display:inline-block;
        font-size: 12px;
        animation: pulseArrow infinite 0.5s alternate;
        animation-play-state: paused;

    }
    &:hover{
        &::after{
            animation-play-state: running;
        }
    }
}


.termsfeed-com---palette-dark .cc-pc-head {
    background: var(--cooki-preferences-head-bg) !important;
    color: var(--cookie-text-color) !important;
    border-bottom: 1px solid var(--cookie-popup-border-color) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-title-headline {
    color: inherit !important;
}

.termsfeed-com---palette-dark .cc-pc-head-close {
    color: inherit !important;
    border: 0!important;
    transition: color 0.3s;

    &:hover {
        color: var(--cookie-text-hover-color) !important;
    }
}

.termsfeed-com---palette-dark .cc-cp-foot {
    border-top-color: var(--persimmon) !important;
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox:checked + label:before {
    background: var(--persimmon) !important;
}

.termsfeed-com---palette-dark .cc-pc-head-lang select {
    border: 1px solid var(--cookie-popup-border-color) !important;
    color: var(--cookie-text-color) !important;
    background: transparent;
    cursor: pointer;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item[active=true] button {
    color: var(--white) !important;
    background: var(--persimmon) !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-content-entry[active=true]{
    background: transparent !important;
    color: var(--cookie-text-color) !important;
}
.termsfeed-com---palette-dark .cc-cp-foot {
    background: var(--cooki-preferences-footer-bg) !important;
    color: var(--cookie-text-color) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item, .termsfeed-com---palette-dark .cc-cp-body-tabs {
    background: var(--cookie-) !important;
}

.termsfeed-com---pc-dialog .cc-cp-body-tabs-item {
    border-color: var(--cookie-popup-border-color) !important;
    box-sizing: border-box!important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs .cc-cp-body-tabs-item[active=true] {
    background: var(--nav-link-activ-bg) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-title, .termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
    color: inherit !important;
}

.termsfeed-com---palette-dark .cc-cp-body .cc-cp-body-content {
    background: var(--white) !important;
}

.termsfeed-com---palette-dark .cc-cp-foot-byline {
    color: var(--black) !important;
}

.termsfeed-com---palette-dark .cc-pc-container {
    background: var(--cookie-popup-bg) !important;
    .cc-cp-body {
        background: var(--cookie-popup-bg) !important;
        color: var(--cookie-text-color) !important;
    }

}
.termsfeed-com---pc-dialog .cc-cp-foot-byline a {
    color: var(--link-color) !important;
    display: inline;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link {
    color: var(--cookie-text-color) !important;
    font-family: var(--font-main)!important;
    font-size: 14px;
    border-radius: 0;

    &:hover {
        background: var(--button-bg-hover);
        color: var(--white, #fff) !important;
    }
}

.termsfeed-com---palette-dark .cc-pc-head-lang select:focus {
    box-shadow: 0 0 0 2px var(--persimmon) !important;
}

.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link:focus {
    box-shadow: none !important;
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
    color: var(--link-color) !important;
    display: inline !important;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

@media only screen and (max-width: 320px) and (min-height: 480px) {
    .termsfeed-com---nb-simple {
        height: auto !important;
    }
}

.termsfeed-com---palette-dark .cc-nb-okagree:focus {
    box-shadow: none !important;
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox+label:after{
    background: var(--amazon-green, #346C50FF);
}
.cc-nb-title-container, .cc-nb-text-container{
    position: relative;
    z-index: 1;
}

.termsfeed-com---palette-dark .cc-pc-head-title-text{
    color: var(--text-color) !important;
}

@media (max-width: 650px) {
    .termsfeed-com---pc-dialog .cc-pc-head-lang{
        padding: 15px 10px 15px 15px!important;
    }
    .termsfeed-com---palette-dark .cc-pc-head-lang select{
        max-width: 80%;
    }
}

/* Cookie popup end*/