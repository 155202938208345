html {
    scrollbar-width: thin;
    scrollbar-color: var(--cl-primary) transparent;
    font-family: var(--font-main);
    font-size: var(--fz);
    line-height: 1.44;
    color: var(--black);
    scroll-behavior: smooth;
    scroll-padding-top: 90px;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--off-white);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--amazon-green);
        border-radius: 0;
    }

    .simplebar-scrollbar::before {
        background-color: var(--black);
        color: var(--black);
        opacity: 1!important;
        left: 1px;
        right: 1px;
    }

    .simplebar-track.simplebar-vertical{
        background: var(--off-white)!important;
        opacity: 1!important;
        width: 5px;
        border-radius: 5px;
        transform: translateX(8px);
    }
}


h1,
h2,
h3,
h4 {

}

h1 {
    font-size: ac(80px, 44px);
    font-weight: 700;
    line-height: 1;
    margin-bottom: ac(50px, 24px);
}

h2 {
    font-size: ac(60px, 34px);
    font-weight: 700;
    line-height: 1.08;
    @media (max-width: 650px) {
        line-height: 1.17;
    }
  mark{
    color: var(--gold);
  }
}

h3 {

}

h4 {

}

h5 {

}

h6 {

}



.content-element{

    font-size: ac(23px, 18px);
    line-height: 1.217;
    h1,
    h2,
    h3,
    h4,
    h5 {
        line-height: 1.25;
        font-weight: 700;
        margin-bottom: 20px;
    }

    h1 {
        font-size: ac(80px, 40px);
    }

    h2 {
        font-size: ac(36px, 28px);
        line-height: 0.97;
        margin-bottom: 20px;
    }

    h3 {
        font-size: ac(26px, 24px);
        line-height: 1.07;
    }

    h4 {
        font-size: ac(45px, 24px);
    }

    h5 {
        font-size: ac(40px, 22px);
    }

    h5 {
        font-size: ac(35px, 20px);
    }

    ul,
    ol {
        list-style: disc;
        padding-left: ac(34px, 20px);
        margin: 20px 0 32px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        &:last-child{
            margin-bottom: 0;
        }
    }

    ol {
        list-style-type: none;
        counter-reset: num;
        padding-left: 0;
        gap: 16px;
        li {
            position: relative;
            counter-increment: num;
            display: flex;
            gap: 12px;
            align-items: flex-start;


            &:before {
                font: inherit;
                content: counter(num);
                width: fit-content;
                border-radius: 6px;
                border: 1px solid var(--amazon-green);
                padding: 5px 8.1px;
                line-height: 1;
                font-size: 14px;
            }
        }
    }

    a {
        display: inline-block;
        color: var(--purple);
        text-decoration: underline;
        transition: 0.3s ease;
        word-break: break-word;

        &:hover {
            color: var(--orange);
        }
    }

    b,
    strong {
        font-weight: bold;
    }

    p {
        margin-bottom: 32px;
    }
}

