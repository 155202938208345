input, textarea {
    @mixin transparent-bg-input var(--amazon-green);
    border: 1px solid var(--off-white);
    padding: 17px ac(23px, 19px);
    border-radius: 10px;
    color: var(--amazon-green);
    font-size: ac(23px, 18px);


    &::placeholder {
        opacity: 0.5;
        font-family: inherit;
        font-weight: inherit;
        color: inherit;
        font-size: inherit;
    }
}
input{
    height: ac(68px, 56px);
}
textarea{
    resize: none;
    height: 116px;
}

.input-wr {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ac(18px, 7px);


    span {
        display: block;
        font-size: ac(18px, 16px);
        font-weight: bold;
        line-height: 1.16;
        b{
            color: var(--gold);
        }
        @media (max-width: 650px) {
            line-height: 1.125;
        }
    }

    input {
        width: 100%;
    }

}


.upload-btn-wr {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: ac(20px, 10px);
    font-size: 20px;
    font-family: var(--font-main);
    font-weight: 600;
    line-height: 0.7;
    cursor: pointer;
    transition: color 0.3s;
    width: 340px;
    max-width: 100%;

    .cv_file_name, .upload-btn-accept{
        transition: color 0.3s, background-color 0.3s;
    }
    &:hover{
        .upload-btn{
            background: var(--amazon-green);
            color: var(--white);
            transform: none;
        }
        .cv_file_name, .upload-btn-accept{
            color: var(--gold);
        }
    }
    input {
        display: none !important;
    }


    .upload-btn-inner{
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        gap: ac(18px, 7px);
    }

    .upload-btn {

        padding: 5px 20px;
        display: flex;
        justify-content: center;
        align-items: center;


        margin-bottom: 10px;
        font-family: var(--font-main);

        &:hover {
            background: var(--amazon-green);
            color: var(--white);
        }
        &-label{
            display: block;
            font-size: ac(18px, 16px);
            font-weight: bold;
            line-height: 1.16;
            b{
                color: var(--gold);
            }
            @media (max-width: 650px) {
                line-height: 1.125;
            }
        }

    }

    .cv_file_name {
        font-size: ac(18px, 14px);
        font-weight: 400;
        line-height: 1.55;
        max-width: 200px;
        @mixin max-line-length-one;
    }
}

label.checkbox-wr, .checkbox-wr {
    --checkbox-size: 26px;
    max-width: 395px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.57;
    display: flex;
    color: rgba(34,74,54, 0.8);
    cursor: pointer;

    input {
        display: none;

        &:checked + span {
            &::after {
                opacity: 1;
            }
            &::before{
                background: var(--amazon-green);
            }
        }
    }

    span {
        position: relative;
        padding-left: 39px;

        &::before {
            content: '';
            display: block;
            width: var(--checkbox-size);
            height: var(--checkbox-size);
            border: 1px solid var(--amazon-green);
            border-radius: 5px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: 0.3s;
        }
        &::after{
            content: '';
            display: block;
            position: absolute;
            left: 13px;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 13.26px;
            aspect-ratio: 13.26 / 10.56;
            background: var(--gold);
            mask-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8261 1.07658C12.0535 0.85115 12.3605 0.724277 12.6807 0.723393C13.0009 0.72251 13.3086 0.847687 13.5373 1.07186C13.7659 1.29604 13.8972 1.6012 13.9027 1.92137C13.9081 2.24154 13.7873 2.551 13.5665 2.78283L7.07945 10.8916C6.96796 11.0117 6.8334 11.108 6.68382 11.1749C6.53423 11.2418 6.37269 11.2779 6.20886 11.2809C6.04503 11.2839 5.88227 11.2539 5.73031 11.1926C5.57835 11.1313 5.44031 11.04 5.32445 10.9241L1.02633 6.62433C0.906588 6.51276 0.810547 6.37821 0.743935 6.22871C0.677323 6.07921 0.641505 5.91782 0.638617 5.75418C0.63573 5.59054 0.665833 5.42799 0.72713 5.27624C0.788426 5.12448 0.879661 4.98663 0.995392 4.8709C1.11112 4.75517 1.24898 4.66393 1.40073 4.60263C1.55249 4.54134 1.71504 4.51123 1.87868 4.51412C2.04232 4.51701 2.2037 4.55283 2.3532 4.61944C2.5027 4.68605 2.63725 4.78209 2.74883 4.90183L6.15158 8.30296L11.7952 1.11233C11.8053 1.09974 11.8161 1.0878 11.8277 1.07658H11.8261Z' fill='%23D6A739'/%3E%3C/svg%3E%0A");
            mask-repeat: no-repeat;
            mask-size: contain;
            opacity: 0;
            transition: opacity 0.3s;
        }
    }

    a {
        display: inline;
        font-weight: inherit;
        font-size: inherit;
        text-decoration: underline;
        transition: 0.3s;
        line-height: inherit;
        color: var(--black);

        &:hover {
            color: var(--gold, #c39947);
        }
    }
}