.home-page {
  .hero-section {
    padding: ac(20px, 107px) 0 ac(78px, 40px);
    display: flex;

    .cont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      gap: 35px;
    }

    .bg-layer {
      width: perc(1957);
      max-width: 1957px;
      min-width: 500px;
      left: 50%;
      bottom: 0;
      transform: translateX(-46%);
    }

    .btn-wr {
      display: flex;
      flex-wrap: wrap;
      gap: ac(25px, 16px);
    }
  }

  .hero-caption {
    align-self: center;
  }
}

.hero-thumbnail-wr {
  position: relative;
  flex: 1;
  width: 100%;
  max-width: 585px;

  &::before {
    content: '';
    display: block;
    padding-top: 121.36%;
  }

  .hero-thumbnail {
    position: absolute;
    overflow: hidden;
    border-radius: 30px;
    width: perc(266, 585);
    transform: translateX(0);

    &::before {
      content: '';
      display: block;
      padding-top: 127.81%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translateX(-50%);
      transition: width 0.5s;
      max-width: revert;
    }

    &:nth-of-type(1) {
      top: 17.8%;
      left: 0;
    }

    &:nth-of-type(2) {
      top: 0;
      right: 0;
    }

    &:nth-of-type(3) {
      right: ac(22px, 9px);
      bottom: 0;
    }

    &:hover {
      img {
        width: 110%;
      }
    }
  }
}

.studies-section {
  padding: ac(100px, 57px) 0 ac(140px, 40px);

  .bg-layer {
    width: perc(1689);
    max-width: 1689px;
    min-width: 500px;

    left: 50%;
    transform: translateX(-54%) scale(0.5);
    bottom: 0;
    opacity: 0;

    &.aos-animate {
      transform: translateX(-54%) scale(1);
      opacity: 0.3;
    }
  }

  .cont {
    display: flex;
    justify-content: space-between;
    gap: ac(35px, 20px);
  }

  .title {
    font-size: ac(60px, 34px);
    font-weight: 700;
    line-height: 1.08;
    margin-bottom: ac(27px, 20px);
    @media (max-width: 650px) {
      line-height: 1.17;
    }
  }

  .section-description {
    font-size: ac(18px, 16px);
    line-height: 1.44;
    @media (max-width: 650px) {
      line-height: 1.5;
    }
  }

  .text-wr {
    width: 501px;
    max-width: 42%;

    .btn {
      margin-top: ac(50px, 32px);
    }
  }
}

.study-swiper-wr {
  padding-top: ac(50px, 19px);
  width: 606px;
  max-width: 54%;

  .swiper:not(.swiper-initialized) {
    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 30px;
    }
  }
}

.study-card {
  width: 100%;

  &-icon {
    width: ac(95px, 72px);
    height: ac(95px, 72px);
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    padding: ac(13px, 8px);
    margin-bottom: 19px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: 0;
      background: var(--amazon-green);
      opacity: 0.4;
    }

    &-box {
      padding: 10px;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: var(--amazon-green);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      position: relative;
      z-index: 1;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-title {
    font-size: ac(23px, 22px);
    line-height: 1.217;
    font-weight: 700;
    margin-bottom: 13px;
    padding-right: ac(60px, 0px);
    @media (max-width: 650px) {
      line-height: 1.27;
    }
  }

  &-text {
    width: 100%;
    --line-count: 7;
    --fz: 16px;
    --line-height: 1.5;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

    &.simplebar-scrollable-y {
      padding-right: 15px;
    }

    .simplebar-scrollbar::before {
      background-color: var(--amazon-green);
    }

    .simplebar-track.simplebar-vertical {
      background: var(--off-white) !important;
      transform: none;
    }
  }
}

.industry-section {
  background: var(--black);
  color: var(--off-white);
  padding: ac(140px, 57px) 0;
  position: relative;
  transform: translate(0);
  overflow: hidden;

  .bg-layer {
    width: perc(1481);
    max-width: 1481px;
    min-width: 1000px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;

    &.aos-animate {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.3;
    }
  }

  .title {
    max-width: ac(703px, 330px, 375, 1440);
    margin-bottom: ac(50px, 40px);
  }

  .sub-title-wr {
    margin-bottom: ac(32px, 15px);
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 37px;

    .swiper-buttons {
      margin-left: auto;

      .swiper-button {
        margin: 0;
      }
    }
  }

  .sub-title {
    font-size: ac(30px, 26px);
    line-height: 1.16;
    font-weight: 700;
    @media (max-width: 650px) {
      line-height: 1.34;
    }
  }
}

.industry-swipers-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 30px;

  @media (max-width: 600px) {
    .swiper {
      position: relative;
      overflow: visible;

      &::before {
        content: '';
        display: block;
        width: 58px;
        height: 100%;
        position: absolute;
        right: -25px;
        top: 0;
        z-index: 2;
        pointer-events: none;
        background: linear-gradient(
          90deg,
          rgba(34, 74, 54, 0),
          rgba(34, 74, 54, 1)
        );
      }
    }

    .swiper-slide {
      width: 232px;
      max-width: 100%;
    }
  }
}

.talent-swiper-wr {
  .option-card li {
    &::before {
      background: url('../../public/images/list-marker-green.svg') no-repeat
        center / contain;
    }
  }
}

.option-card {
  font-size: ac(23px, 18px);
  line-height: 1.21;
  font-weight: 700;
  @media (max-width: 650px) {
    line-height: 1.55;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ac(0px, 3px);
  }

  li {
    border-bottom: 1px solid var(--amazon-green);
    padding: ac(20px, 10px) 10px ac(19px, 10px) ac(33px, 29px);
    position: relative;

    &::before {
      content: '';
      display: block;
      width: ac(18px, 16px);
      height: ac(10px, 9px);
      background: url('../../public/images/list-marker-gold.svg') no-repeat
        center / contain;
      position: absolute;
      left: 0;
      top: ac(28px, 19px);
    }
  }
}

.hire-section {
  padding: ac(140px, 48px) 0;

  .title {
    margin-bottom: ac(35px, 21px);
  }

  .section-columns {
    display: flex;
    gap: ac(30px, 20px);
    padding-bottom: ac(60px, 18px);
  }

  .section-motto {
    font-size: ac(25px, 20px);
    line-height: 1.32;
    font-weight: 600;
    flex: 1;
    max-width: 500px;

    @media (max-width: 650px) {
      line-height: 1.4;
    }
  }

  .section-description {
    font-size: ac(18px, 16px);
    line-height: 1.44;
    flex: 1;
    max-width: 610px;
    @media (max-width: 650px) {
      line-height: 1.5;
    }
  }

  &-list {
    display: flex;
    gap: 25px;
    padding-bottom: 30px;
    flex-wrap: wrap;

    li {
      flex: 1;
      position: relative;
      padding-left: 30px;
      font-size: ac(18px, 16px);
      line-height: 1.44;
      min-width: 250px;

      &::before {
        content: '';
        display: block;
        width: ac(18px, 16px);
        height: ac(10px, 9px);
        background: url('../../public/images/list-marker-gold.svg') no-repeat
          center / contain;
        position: absolute;
        left: 0;
        top: calc((ac(30px, 25px) * 1.44) / 2);
        transform: translateY(-50%);
      }
    }

    h3 {
      font-size: ac(30px, 25px);
      font-weight: 500;
      margin-bottom: 15px;
    }
  }
}

.counts-swiper-wr {
  padding-top: 21px;
  position: relative;

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }

  @media (min-width: 451px) and (max-width: 992px) {
    .swiper-slide {
      width: ac(250px, 219px, 375, 992);
    }
  }

  @media (max-width: 450px) {
    &::before {
      content: '';
      display: block;
      width: 132px;
      height: 100%;
      position: absolute;
      right: -25px;
      z-index: 2;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
      pointer-events: none;
      transition: 0.3s;
    }

    .swiper {
      width: 219px;
      margin-left: 0;
    }

    &.slides-end {
      &::before {
        opacity: 0;
      }
    }
  }
}

.count-card {
  width: 100%;
  background: var(--amazon-green);
  border-radius: var(--bd-radius);
  padding: 26px;

  .count-up-wr {
    font-size: ac(80px, 54px);
    display: flex;
    align-items: flex-end;
    color: var(--gold);
    line-height: 1;
    margin-bottom: 13px;

    span {
      font-size: ac(40px, 34px);
      padding-left: ac(7px, 5px);
      padding-bottom: ac(8px, 2px);
    }
  }

  &-text {
    --line-count: 3;
    --fz: ac(23px, 18px);
    --line-height: 1.217;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));
    width: 100%;
    font-weight: 700;
    color: var(--off-white);

    @media (max-width: 650px) {
      --line-height: 1.55;
    }
  }
}

.ts-section {
  padding: ac(142px, 49px) 0 180px;
  background: var(--amazon-green);
  color: var(--white);

  .title {
    margin-bottom: ac(50px, 20px);
  }

  & + .banner-section {
    .banner-box {
      margin-top: ac(-160px, -253px);
    }
  }
}

.ts-swipers-wrapper {
  display: flex;
  justify-content: space-between;
  gap: ac(30px, 20px);
}

.ts-card-swiper-wr {
  width: ac(394px, 351px);
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  min-height: 280px;

  .swiper {
    width: 100%;
    margin: 0;
    height: ac(110px, 92px);
    overflow: visible;
  }

  .swiper-slide {
    height: fit-content;

    &.swiper-slide-active {
      .ts-card {
        background: var(--white);
        color: var(--amazon-green);
      }
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% - 120px);
    position: absolute;
    left: 0;
    bottom: -1px;
    background: linear-gradient(rgba(52, 108, 80, 0), rgba(52, 108, 80, 1));
    z-index: 2;
    pointer-events: none;
    transition: height 0.3s;
  }

  &.slides-end {
    &::after {
      height: 120px;
    }
  }
}

.ts-text-swiper-wr {
  width: ac(712px, 342px);
  max-width: 100%;
  padding-bottom: 100px;

  .swiper-button {
    margin-top: ac(57px, 33px);
  }
}

.ts-card {
  height: fit-content;
  min-height: ac(110px, 92px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: var(--bd-radius);
  background: var(--black);
  color: var(--off-white);
  padding: ac(28px, 20px) ac(30px, 24px) ac(28px, 22px);
  font-size: ac(16px, 14px);
  line-height: 1.125;
  transition: 0.3s;
  cursor: pointer;
  @media (max-width: 650px) {
    line-height: 1.1428;
  }

  div {
    font-size: ac(23px, 20px);
    font-weight: 700;
    line-height: 28px;
    margin-bottom: ac(7px, 5px);
  }
}

.ts-text-card {
  &-icon {
    font-size: ac(36px, 28px);
    color: var(--gold);
    margin-bottom: ac(18px, 19px);
    line-height: 1;
  }

  &-text {
    --fz: ac(25px, 20px);
    --line-count: 11;
    --line-height: 1.32;
    line-height: var(--line-height);
    font-size: var(--fz);
    max-height: calc(var(--fz) * var(--line-height) * var(--line-count));

    @media (max-width: 650px) {
      --line-height: 1.3;
    }

    &.simplebar-scrollable-y {
      padding-right: 15px;
    }

    .simplebar-track.simplebar-vertical {
      transform: translateX(0px);
    }
  }
}

.banner-section {
  padding: 20px 0 40px;
}

.banner-box {
  background: var(--snow-white);
  border-radius: var(--bd-radius);
  padding: ac(60px, 32px) ac(60px, 24px) ac(60px, 38px);
  display: flex;
  gap: 33px;
  justify-content: space-between;
  /* margin-top: ac(-160px, -253px);*/
  margin-left: ac(-35px, 0px);
  margin-right: ac(-35px, 0px);
  position: relative;
  z-index: 2;

  &-logo {
    flex-shrink: 0;
    width: ac(370px, 279px);
    max-width: 100%;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &-text {
    flex: 1;
    max-width: 687px;
  }

  .title {
    margin-bottom: ac(25px, 12px);
  }

  &-description {
    font-size: ac(18px, 16px);
    line-height: 1.44;
    @media (max-width: 650px) {
      line-height: 1.5;
    }
  }
}

.jobs-section {
  padding: ac(99px, 19px) 0;

  .jobs-section-caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px;
    padding-bottom: ac(47px, 33px);
  }

  .title-wr {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ac(56px, 20px);
  }

  .swiper-button {
    margin: 0;
  }

  .meta-select {
    width: 288px;
    flex-shrink: 0;
  }
}

.jobs-swiper-wr {
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    display: flex;
    height: auto;
  }

  @media (max-width: 1200px) {
    .swiper-slide {
      width: fit-content;
      max-width: 100%;

      .job-card {
        width: ac(351px, 302px, 375, 1200);
        max-width: 100%;
      }
    }
  }
}

.job-card {
  width: 100%;
  border-radius: var(--bd-radius);
  padding: ac(30px, 19px) ac(28px, 19px) ac(30px, 19px) ac(30px, 19px);
  border: 1px solid var(--black);
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  color: var(--amazon-green);

  &-title {
    font-size: ac(30px, 24px);
    line-height: 1.16;
    margin-bottom: ac(55px, 36px);
    flex-grow: 1;
    font-weight: bold;
    @mixin max-line-length 3;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ac(7px, 5px);
  }

  li {
    font-size: ac(23px, 16px);
    line-height: 1.217;
    font-weight: bold;
    @mixin max-line-length 1;

    @media (max-width: 650px) {
      line-height: 1.75;
    }
  }

  .btn {
    margin-top: ac(30px, 15px);
  }

  &:hover {
    background: var(--gold);
    color: var(--white);
  }
}

.blog-banner-section {
  padding: ac(40px, 48px) 0 ac(40px, 38px);
}

.blog-banner {
  background: var(--black);
  border-radius: var(--bd-radius);
  margin-left: ac(-35px, 0px);
  margin-right: ac(-35px, 0px);
  padding: ac(70px, 34px) ac(115px, 24px) ac(70px, 40px);
  color: var(--white);
  display: flex;
  align-items: center;
  gap: ac(142px, 32px);
}

.contact-section {
  padding: ac(100px, 19px) 0 ac(43px, 31px);

  .cont {
    display: flex;
    gap: 35px;
    justify-content: space-between;
  }

  .contact-info-list {
    display: none;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;

    li {
      font-size: ac(23px, 24px);
      line-height: 28px;

      span {
        font-weight: bold;
        font-size: ac(18px, 20px);
        line-height: 1.16;
        display: block;
        margin-bottom: 12px;

        @media (max-width: 650px) {
          line-height: 1.125;
        }
      }

      a {
        transition: 0.3s;
        word-break: break-all;

        &:hover {
          color: var(--gold);
        }
      }
    }
  }

  &-caption {
    flex: 1;
    max-width: ac(440px, 300px);

    .contact-info-list {
      display: flex;
    }
  }

  .title {
    margin-bottom: 50px;
  }
}

.contact-form {
  flex: 1;
  max-width: 712px;
  display: flex;
  flex-direction: column;
  gap: ac(30px, 17px);
  padding-top: 8px;
}

@media (max-width: 992px) {
  .studies-section {
    .cont {
      flex-direction: column;
    }

    .text-wr,
    .study-swiper-wr {
      max-width: 100%;
      width: 100%;
    }

    .study-swiper-wr {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc(100% - 29px);
        height: 100%;
        width: 100vw;
        z-index: 3;
        pointer-events: none;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 53px
        );
        transition: 0.3s;
      }

      .swiper {
        overflow: visible;
        position: relative;
        max-width: 100%;
        margin-left: 0;
      }

      &.slides-end {
        &::before {
          opacity: 0;
        }
      }
    }
  }
}

@mixin mobile-menu-start-point {
  .home-page {
    .hero-section {
      .btn-wr {
        flex-direction: column;
        width: 100%;
      }
    }

    .hero-caption {
      align-self: flex-start;
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .home-page {
    .hero-section {
      .bg-layer {
        width: perc(1037, 375);
        transform: translateX(-53.2%);
      }

      padding-top: 107px;

      .cont {
        flex-direction: column-reverse;
        justify-content: center;
        gap: 30px;
      }
    }

    .hero-caption {
      align-self: flex-start;
    }
  }

  .hero-thumbnail-wr {
    flex: revert;
    width: 62%;
    min-width: 217px;
    align-self: flex-end;
  }

  .industry-swipers-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .hire-section .section-columns {
    flex-direction: column;
  }

  .ts-swipers-wrapper {
    flex-direction: column;
  }

  .ts-card-swiper-wr {
    min-height: revert;
    overflow: visible;
    width: 100%;

    &::after {
      width: 123px;
      height: 100% !important;
      left: auto;
      right: -25px;
      bottom: 0;
      transition: 0.3s;
      background: linear-gradient(90deg, #346c5000, #346c50);
    }

    .swiper {
      overflow: visible;
      height: auto;
      width: ac(260px, 220px, 375, 768);

      .swiper-slide {
        display: flex;
        height: auto;

        .ts-card {
          height: auto;
        }
      }
    }

    &.slides-end {
      &:after {
        width: 50px;
        height: 100%;
        opacity: 0;
      }
    }
  }

  .ts-text-swiper-wr {
    width: 100%;
  }

  .banner-box {
    flex-direction: column;
    align-items: center;
  }

  .contact-section {
    .title {
      margin-bottom: 0;
    }

    .cont {
      flex-direction: column;
      gap: 24px;
    }

    .contact-info-list {
      display: flex;
      flex-direction: column-reverse;
      padding-top: 8px;
      gap: 25px;
    }

    &-caption {
      max-width: 100%;
      width: 100%;

      .contact-info-list {
        display: none;
      }
    }
  }

  .jobs-section {
    padding-bottom: 68px;

    .swiper-buttons {
      position: absolute;
      left: 24px;
      bottom: 0;
    }
  }
}

@media (max-width: 600px) {
  .blog-banner {
    flex-direction: column;
  }

  .studies-section {
    .bg-layer {
      display: none;
    }
  }

  .study-card {
    &-header {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 12px;
    }

    &-title {
      margin: 0;
    }

    &-icon {
      flex-shrink: 0;
      margin: 0;

      &-box {
        padding: 14px;
      }
    }
  }

  .count-card {
    padding: 27px 24px 24px 24px;
  }

  .ts-section .title {
    padding-right: ac(70px, 50px, 320, 450);
  }
}

@media (max-width: 550px) {
  .studies-section .study-swiper-wr .swiper {
    width: ac(320px, 294px, 375, 550);
  }
}

@media (max-width: 450px) {
  .jobs-section {
    .title-wr {
      width: 100%;
    }

    .meta-select {
      width: 100%;
    }
  }

  .contact-form {
    textarea {
      height: 56px;
      padding-top: 15px;
      padding-bottom: 10px;
    }

    .btn {
      margin-top: 3px;
    }
  }
}

@media (max-width: 370px) {
  .contact-section .contact-info-list li {
    font-size: 20px;
  }
}
