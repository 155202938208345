.popup {
  --py: ac(20px, 10px);
  --px: ac(20px, 10px);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8999;
  display: none;
  padding: var(--py) var(--px);
  justify-content: center;

  &.active {
    display: flex;
  }

  &__close-bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 0;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    margin: 0 auto;
    --pp-opacity-from: 0;
    --pp-opacity-to: 1;
    background: var(--white);
    position: relative;
    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);
    transition: all 300ms ease-out;
    animation-duration: 0.6s;
    z-index: 1;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }

    .simplebar-content-wrapper {
      display: block !important;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  .close-btn {
    position: absolute;
    right: ac(40px, 20px);
    top: ac(40px, 20px);
  }

  &.justify-center {
    .simplebar-content-wrapper:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .simplebar-track.simplebar-vertical {
    transform: none;
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}

.job-popup-block {
  padding: 0;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: perc(1120);
  max-width: 1120px;
  @media (max-width: 1200px) {
    width: 100%;
  }

  .success-title {
    text-align: center;
    margin-top: 10px;
  }

  .popup-title {
    font-weight: 700;
    font-size: ac(40px, 20px);
  }
}

.popup-header {
  width: 100%;
  padding: 42px ac(120px, 90px) 20px ac(64px, 20px);
  min-height: ac(140px, 100px);
  background: var(--black);
  color: var(--white);
  gap: 35px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.popup-tab-wrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.popup-main {
  height: fit-content;
  padding: 27px ac(120px, 10px) 40px ac(64px, 20px);
  flex-grow: 1;
  max-height: 839px;
  display: flex;

  &-tab {
    width: 100%;
    opacity: 0;
    display: none;

    &.active {
      display: block;
      animation: fadeIn forwards 0.5s linear;
    }
  }

  .simplebar-track.simplebar-vertical {
    transform: translateX(ac(-32px, -2px));
    top: 10px;
    bottom: 10px;
  }
}

.popup-job-info {
  display: flex;
  flex-wrap: wrap;
  gap: 15px ac(64px, 25px);
  padding-bottom: 24px;
  width: 100%;
  border-bottom: 1px solid var(--urban-pigeon);
  margin-bottom: ac(40px, 20px);


  li {
    display: flex;
    flex-direction: column;
    gap: ac(20px, 15px);

    span {
      font-size: ac(16px, 14px);
      line-height: 1.625;
      font-weight: 600;
      text-transform: uppercase;
    }

    div {
      font-weight: bold;
      font-size: ac(23px, 18px);
      line-height: 1.217;
    }

  }
  @media (max-width: 420px){
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px 10px;
  }
}

.popup-footer {
  display: flex;
  min-height: 132px;
  justify-content: space-between;
  align-items: center;
  padding: 30px ac(64px, 20px);
  background: var(--snow-white);
  color: var(--black);

  &-tab {
    /*display: flex;*/
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    display: none;
    opacity: 0;

    &.active {
      display: flex;
      animation: fadeIn forwards 0.5s linear;
    }
  }

  &-buttons {
    display: flex;
    flex-shrink: 0;
    margin-left: auto;
    flex-wrap: wrap;
    gap: ac(24px, 10px);
  }

  .checkbox-wr {
    max-width: 395px;
  }
}

.popup-author {
  display: flex;

  gap: 16px;

  &-thumbnail {
    width: 72px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--black);

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    gap: 4px;
    font-size: ac(18px, 16px);
    border-right: 1px solid var(--urban-pigeon);
    padding-right: 16px;
    padding-top: 2px;
  }

  &-name {
    font-size: ac(23px, 18px);
    font-weight: 700;
    line-height: 1.217;
    color: var(--amazon-green);
  }

  &-info {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4px;
    gap: 8px;
    line-height: 1.217;
    font-size: ac(23px, 18px);
    font-weight: 500;
    align-items: flex-start;

    a {
      transition: 0.3s;
      font-weight: 400;

      &:hover {
        color: var(--gold);
      }
    }
  }
}

.apply-popup-form {
  .form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 18px 24px;
  }
}

.form-grid-upload {
  grid-column: 1/-1;
  display: flex;
  gap: 24px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .apply-popup-form .form-grid {
    grid-template-columns: 1fr;
  }

  .form-grid-upload {
    flex-direction: column;

    .upload-btn-wr {
      flex-wrap: wrap;
      width: 100%;
    }
  }
}

@media (max-width: 650px) {
  .popup-footer-tab {
    flex-direction: column;
    align-items: center;

    .popup-footer-buttons {
      margin: 0 auto;
    }
  }

  .popup-author {
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6px 10px;

    &-info{
      width: 100%;
      align-items: center;
      text-align: center;
    }

    &-thumbnail {
      margin-bottom: 5px;
      width: 65px;
    }

    &-text {
      border: 0;
      padding: 0;
      max-width: calc(100% - 75px);
    }
  }
}

.subscribe-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px ac(20px, 10px);
  transition: opacity 0.3s;

  &:not(.active) {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    .subscribe-popup-block {
      transform: translateY(-20px);
    }
  }

  .success-title {
    text-align: center;
    margin-top: 10px;
  }

  &-block {
    width: perc(1164);
    max-width: 1124px;
    background: var(--white);
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: ac(32px, 20px);
    transition: 0.3s;

    @media (max-width: 1100px) {
      width: 100%;
    }
  }

  .close-btn {
    position: absolute;
    right: ac(40px, 20px);
    top: ac(40px, 20px);
  }

  &-bg {
    content: '';
    display: block;
    z-index: 0;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  &-main {
    padding: ac(45px, 30px) ac(117px, 20px) 48px ac(60px, 20px);
  }

  &-footer {
    padding: 32px ac(60px, 20px);
    background: var(--snow-white);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .popup-title {
    color: var(--amazon-green);
    margin-bottom: ac(53px, 40px);
    line-height: 1.125;
    font-size: ac(40px, 28px);
    font-weight: 700;
  }

  .input-wr {
    gap: ac(14px, 7px);
  }
}

.subscribe-popup-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 18px;
}

.popup-main {
  @media (max-height: 1130px) {
    max-height: 609px;
  }
  @media (max-height: 900px) {
    max-height: 475px;
  }
  @media (max-height: 760px) {
    max-height: 395px;
  }
  @media (max-height: 740px) {
    max-height: 310px;
  }

  @media (max-width: 650px) {
    @media (max-height: 900px) {
      max-height: 389px;
    }
    @media (max-height: 825px) {
      max-height: 289px;
      & + .popup-footer{
        padding-top: 13px;
        padding-bottom: 13px;
      }
    }
    @media (max-height: 740px){
        max-height: 215px;
    }
  }
}
