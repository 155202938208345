.fp-watermark {
    display: none;
}


.swiper-wrapper {
    left: 0 !important;
}

#site {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: calc(100 * var(--vh));
    overflow: hidden;
}

.main {
    flex-grow: 1;
}

.cont {
    margin: 0 auto;
    max-width: 1290px;
    padding: 0 24px;
    width: perc(1290);

    @media (max-width: 992px) {
        width: 100%;
    }
}

section {
    position: relative;
}

.logo {
    transition: 0.3s ease;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &:hover {
        transform: scale(1.06);
    }
}

#bold-credits {
    width: 148px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }

    @mixin max-sm {
        width: 159px;
    }
}

#site.cookie-terms-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .main {
        flex-grow: 1;
        padding: 0;
    }
}

.cookie-terms-section {
    padding: ac(250px, 140px) 0 ac(150px, 50px);


    p {
        padding: 0;
        margin-bottom: 10px;
    }

    ul,
    ol {
        padding-left: 20px;

        li {
            p {
                margin-bottom: 0;
            }
        }
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    a {
        display: inline;
        text-decoration: underline;
        transition: 0.2s;

        &:hover {
            color: var(--gold);
        }
    }
}

.single-page-404 {
    min-height: 100vh;
    padding:24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    h1,
    p {
        margin-bottom: 15px;
        padding: 0;
        text-align: center;
    }

    a {
        margin-top: 20px;
    }
}

.hero-section {
    min-height: calc(100 * var(--vh, 1vh));
    background: var(--black);
    transform: translateX(0);
    color: var(--off-white);

}
.bg-layer{
    position: absolute;
    z-index: -1;
    img{
        width: 100%;
        height: auto;
    }
}

.swiper:not(.swiper-initialized){
    & + .swiper-buttons{
        display: none;
    }
}
.success-title{
    font-size: ac(43px, 26px);
    margin: 0;
    font-weight: 500;
    line-height: 1;
}
.grecaptcha-badge{
    z-index: 4;
    visibility: hidden;
    opacity: 0;
}

.title-swiper-wr{
    color: var(--gold);
}