/* ----------------------- Common desktop header ----------------------- */
.header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: ac(60px, 14px) 0 14px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &.header-dark {
        &::before {
            background: var(--black);
            opacity: 0.9;
            inset: revert;
            width: 100%;
            left: 0;
            top: 0;
            height: calc(100% + ac(10px, 0px));
        }
    }

    &.absolute {
        position: absolute;
    }

    &.fixed {
        position: fixed;
    }

    &:before {
        position: absolute;
        inset: 0;
        content: '';
        z-index: -1;
        background-color: transparent;
        opacity: 0;
        transition: all 0.25s ease;

    }

    &.scrolled {
        &:not(.open-menu) {
            background: rgba(var(--cl-grey), 0.8);
            -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
            padding: ac(20px, 10px) 0;

            &:before {
                opacity: 0.9;
                background-color: var(--black);
            }

            .logo {
                transform: scale(0.9);
                &:hover{
                    transform:scale(0.95);
                }
            }
        }
    }

    &.menu-open {
        background: var(--black);
    }

    .logo {
        width: ac(182px, 172px);
        flex-shrink: 0;
        transition: transform 0.25s ease, width 0.25s ease;
        z-index: 100;

        &:hover {
            transform: scale(1.06);
        }

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: left;
        }
    }

    .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .burger {
        display: none;
    }

}

.navbar-nav {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 30px;
    z-index: 79;
    display: flex;
    pointer-events: none;

    &-wrapper {
        width: 100%;
        padding: 10px;
        animation: fadeUp forwards 0.8s 1s;
        opacity: 0;
    }


    .menu {
        pointer-events: all;
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 0 auto;
        background: var(--black);
        box-shadow: 0 5px 3px rgba(0, 0, 0, 0.15);
        border-radius: 60px;
        position: relative;

        padding: 5px;


        /*transition: opacity 0.2s ease;
        opacity: 0;*/

        @mixin mobile-menu-end-point {

        }

        &:not(:hover) {
            .menu-item-slider {
                opacity: 0;
            }
        }
    }

    .menu-item {
        flex-shrink: 0;
    }

    .menu-item-slider {
        position: absolute;
        left: 10%;
        top: 50%;
        height: calc(100% - 10px);
        border-radius: 60px;
        width: 0;
        background: var(--off-white);
        transform: translateY(-50%);


        transition: width 0.3s, opacity 0.3s 0s;
        opacity: 0;

        &.active {
            opacity: 0.15;
            transition: 0.3s left, width 0.3s, opacity 0.3s 0s;
        }
    }

    .menu-link {
        position: relative;
        font-size: 20px;
        font-family: var(--font-main);
        line-height: normal;
        font-weight: 700;
        transition: all 0.3s ease;
        cursor: pointer;
        padding: 10px 15px;
        color: var(--off-white);


        &.menu-link-logo {

            padding: 0;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    &-footer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        width: 375px;
        max-width: 100%;
        margin: 0 auto;
        display: none;
        padding: 0 24px;

        .btn {
            width: 100%;
        }
    }

}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@mixin mobile-menu-start-point {
    .header {
        position: fixed;


        .burger,
        .menu-toggle {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 6px solid var(--black);
            background: var(--gold);
            box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.15);
            padding: 15px 11px;
            cursor: pointer;
            transition: transform 0.3s;

            div {
                height: 3px;
                background: var(--amazon-green);
                width: 26px;
                transition: transform 0.3s, opacity 0.3s;

                &:nth-of-type(1) {
                    transform-origin: left top;
                }

                &:nth-of-type(2) {
                    transform-origin: left center;
                }

                &:nth-of-type(3) {
                    transform-origin: left bottom;
                }
            }

            &:hover {
                transform: scale(1.1);
            }

            &.active {
                div {
                    &:nth-of-type(1) {
                        transform: rotate(45deg) translate(12%, -161%);
                    }

                    &:nth-of-type(2) {
                        transform: translateX(-100%);
                        opacity: 0;
                    }

                    &:nth-of-type(3) {
                        transform: rotate(-45deg) translate(12%, 161%);
                    }
                }
            }
        }

        .navbar {
            justify-content: space-between;
        }
    }

    .navbar-nav {
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        height: calc(var(--dvh, 1vh) * 100);
        min-width: 320px;
        background: var(--black);
        transition: transform 0.3s ease-out;
        overflow: hidden;
        padding: 88px 0 40px;
        display: flex;
        flex-direction: column;
        background: var(--gold);
        pointer-events: auto;

        &-wrapper {
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 0 20px;
            margin-top: auto;
            margin-bottom: auto;
        }

        &-footer {
            display: flex;
            margin-top: 22px;
        }


        &.nav-slide-down {
            right: auto;
            left: 0;
            transform: translateY(-100%);

            &.active {
                transform: translateY(0);
            }
        }

        &.nav-slide-up {
            right: auto;
            left: 0;
            transform: translateY(100%);

            &.active {
                transform: translateY(0);
            }
        }

        .menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            box-shadow: none;
            background: none;
            border-radius: 0;
            flex-grow: 1;
            gap: 24px;
            padding: 0;
        }

        .menu-link {
            padding: 0;
            font-weight: 700;
            font-size: 26px;
            color: var(--black);

            &-logo {
                display: none;
            }
        }


        .simplebar-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
        }

        .simplebar-track.simplebar-vertical {
            width: ac(6px, 4px);
            background: var(--off-white);
            border-radius: 4px;
            transform: translateX(-5px);

            .simplebar-scrollbar {
                background: var(--amazon-green);
                border-radius: 4px;

                &:before {
                    content: none;
                }
            }
        }

    }

    @media (max-height: 660px) {
        .navbar-nav {
            padding-bottom: 20px;

            .menu {
                gap: 14px;
            }

            &-footer {
                margin-top: 20px;
            }
        }
    }


    .navbar-nav-wrapper {
        @media (max-height: 900px) {
            max-height: 390px;
        }
        @media (max-height: 640px) {
            max-height: 290px;
        }
        @media (max-height: 550px) {
            max-height: 230px;
        }
        @media (max-height: 490px) {
            max-height: 140px;
        }
        @media (max-height: 400px) {
            max-height: 90px;
        }
    }

}

/* ----------------------- HEADER COMPONENTS ----------------------- */


.header-close-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 77;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;

    &.active {
        visibility: visible;
        pointer-events: auto;
        opacity: 1;
        cursor: pointer;
    }
}

/* end of HEADER COMPONENTS */

