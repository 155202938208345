.btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  padding: 10px ac(30px, 20px);
  gap: 20px;
  background: var(--gold);
  color: var(--black);
  border-radius: 8px;
  transition: 0.3s;
  line-height: 1;
  font-weight: 700;
  font-size: ac(20px, 18px);
  min-height: ac(68px, 56px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0);
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    transform: translateY(-2px);
    background: var(--amazon-green);
    color: var(--white);

    &::after {
      animation-play-state: running;
    }
  }

  &::after {
    content: '\e900';
    font-family: 'icomoon' !important;
    font-size: 14px;
    display: block;
    animation: pulseArrow infinite 0.5s alternate;
    animation-play-state: paused;
  }

  &.btn-green {
    background: var(--amazon-green);
    color: var(--white);

    &::after {
      color: var(--gold);
    }

    &:hover {
      color: var(--gold);
    }
  }

  &.btn-border-green {
    background: transparent;
    border: 1px solid var(--amazon-green);

    &::after {
      color: var(--gold);
    }
      &:hover{
          background: var(--amazon-green);
      }
  }

  &.flex-row-reverse {
    &::after {
      content: '\e901';
    }
  }
}

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: 15px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 20px 0 0;
    right: auto;
    top: auto;
    bottom: auto;
    left: auto;
    display: flex;
    width: 37px;
    height: 37px;
    justify-content: center;
    align-items: center;
    color: var(--black, #346c50);
    border: 1px solid var(--black, #346c50);
    border-radius: 8px;
    transition: 0.3s;

    &::after {
      display: none;
    }

    &::before {
      content: '\e905';
      display: block;
      font-family: 'icomoon' !important;
    }

    &.swiper-button-prev {
      &::before {
        content: '\e904';
      }
    }

    &:hover {
      background: var(--amazon-green, #346c50);
      color: var(--white, #fff);
    }

    &.swiper-button-white {
      border-color: var(--white);
      color: var(--white);

      &:hover {
        background: var(--gold);
      }
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}

.social-buttons {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.soc-btn {
  width: ac(66px, 56px);
  height: ac(66px, 56px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--gold);
  color: var(--black);
  font-size: ac(23px, 19px);
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0);
  transition: 0.3s;

  i {
    display: block;
    line-height: 1;
    transition: transform 0.3s;
    transform: translateY(-5%);
  }

  &:hover {
    background: var(--amazon-green);
    color: var(--white);
    transform: scale(1.05);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);

    i {
      transform: translateY(-8%);
    }
  }
}

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: ac(60px, 48px);
  height: ac(60px, 48px);
  border-radius: 50%;
  border: 6px solid var(--black);
  background: var(--amazon-green);
  box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.15);
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;
  font-size: ac(20px, 14px);

  &:hover {
    background: var(--gold);
    transform: scale(1.1);
  }
  &.close-btn-white{
    border-color: var(--white);
    color: var(--white);
  }
}

@media (max-width: 450px) {
  .btn {
    width: 100%;
    max-width: 100%;
  }
}

@keyframes pulseArrow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5px);
  }
}


