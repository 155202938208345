@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');


:root {

    --black: #224A36; /* everglade green:*/
    --off-white: #E1E5E0;
    --snow-white: #ECECEC;
    --gold: #D6A739;
    --amazon-green: #346C50; /*amazon green*/
    --everglade-green: #224A36;
    --white: #fff;
    --urban-pigeon: #9eabb8;

    --blue: #314D89;
    --lime: #6DB340;
    --gradient: linear-gradient(rgba(52, 108, 80, 0), rgba(52, 108, 80, 1));
    --bd-radius: ac(31px, 20px);


    --cl-black-rgb: 0 0 0;

    --cl-primary: #224A36;
    --cl-primary-rgb: 236 97 42;

    --cl-white: #ffffff;
    --cl-white-rgb: 255 255 255;

    --font-main: 'Arimo', sans-serif;
    --font-second: 'Manrope', sans-serif;
    --fz: 16px;

    /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
    --mobile-menu-start-point: 768;

    --vh: calc(100vh / 100);
    --dvh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */

@define-mixin mobile-menu-start-point {
    @media (max-width: 768px) {
        @mixin-content;
    }
}
@define-mixin mobile-menu-end-point {
    @media (min-width: 769px) {
        @mixin-content;
    }
}

@keyframes fadeUp {

    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}

