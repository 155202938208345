.footer {
  padding: 0 0 110px;
  color: var(--cl-white);
  &-border-top{
    border-top: 1px solid var(--black);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
  }
  .cont{
    padding-top: ac(29px, 25px);
    position: relative;
  }
  &-logo-wr{
    display: flex;
    gap: ac(40px, 20px);
    align-items: center;
    margin-bottom: 31px;
  }

  &-logo {
    width: ac(182px, 192px);
    flex-shrink: 0;
    transition: 0.3s;
    transform: translateY(-14%);
    img{
      width: 100%;
    }

    &::before {
      display: none;
    }

    &:hover {
      transform: scale(1.06) translateY(-14%);
    }
  }

  &-copyright{
    font-size: 14px;
    line-height: 1.14;
    color: var(--amazon-green);
    margin-bottom: 40px;
  }
}

@media (max-width: 450px) {
  .footer {
    &-logo{
      transform: none;
      &:hover{
        transform: scale(1.06);
      }
    }

    &-logo-wr{
      justify-content: space-between;
      margin-bottom: 13px;
    }
  }
}
